<template>
  <div class="card" style="min-height: calc(100vh - 180px)">
    <div class="bg-blue-light p-1">
      <TitleButton
          btnTitle="List"
          :showBtn="true"
          :showAddNew="false"
          title="Add Production Entry"
          @onClickCloseButton="navigateToListPage"
      />
      <div class="row gy-1">
        <div class="col-md-4">
          <div class="col-md-12 col-12">
            <label class="form-label" for="product_costing_master_id">Product Costing</label>
            <vField
                v-model="formData.product_costing_master_id"
                name="product_costing_master_id"
                type="text"
                class="form-control d-none"
            />
            <AsyncSelect
                placeholder="Select Product Costing"
                v-model="productCosting"
                :api-service="fetchProductCosting"
            />
          </div>
          <div class="col-md-12 col-12">
            <label for="colFormLabel" class="form-label">Product Name: </label>
            <input
                class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                tabindex="1"
                type="text"
                placeholder="Product Name"
                readonly
                v-model="formData.product_name"
            >
          </div>
          <div class="col-12">
            <label for="colFormLabel" class="form-label">Batch No: </label>
            <input
                class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                tabindex="1"
                type="text"
                placeholder="Batch No"
                v-model="formData.batch_no"
            >
          </div>
          <div class="col-12">
            <label for="colFormLabel" class="form-label">Exp date: </label>
            <input
                class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                tabindex="1"
                type="date"
                v-model="formData.expire_date"
            >
          </div>
        </div>
        <div class="col-md-4">
          <div class="col-md-12 col-12">
            <label for="colFormLabel" class="form-label">Production Qty: </label>
            <input
                class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                tabindex="1"
                type="number"
                placeholder="Production Qty"
                v-model="formData.production_quantity"
            >
          </div>
          <div class="col-md-12 col-12">
            <label for="colFormLabel" class="form-label">Rate: </label>
            <input
                class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                tabindex="1"
                type="number"
                placeholder="Rate"
                readonly
                v-model="getRateAmount"
            >
          </div>
          <div class="col-md-12 col-12">
            <label for="colFormLabel" class="form-label">FG Value: </label>
            <input
                class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                tabindex="1"
                type="number"
                placeholder="FG Value"
                v-model="getFGValueAmount"
            >
          </div>
        </div>
        <div class="col-md-4">
          <div class="col-md-12 col-12">
            <label for="colFormLabel" class="form-label">Manufacturing Date: </label>
            <input
                class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                tabindex="1"
                type="date"
                placeholder="Manufacturing Date"
                v-model="formData.date"
            >
          </div>
          <div class="col-md-12 col-12">
            <label for="colFormLabel" class="form-label">FG warehouse: </label>
            <v-select
                placeholder="Select Warehouse Location"
                v-model="formData.finished_goods_location_id"
                :options="locations"
                label="text"
                :reduce="text => text.id"
                @option:selected="resetForm"
            />
          </div>
          <div class="col-12">
            <div class="d-flex align-items-center justify-content-between filter-label">
              <label for="colFormLabel" class="form-label">Customer Name: </label>
              <span class="filter-search-icon cursor-pointer" @click="onOpenContactSearchModal">
                  <i class="fas fa-search"></i>
              </span>
            </div>

            <AsyncSelect
                placeholder="Select Customer"
                v-model="contactProfile"
                :api-service="fetchContactProfiles"
                :format-label="formatPatientLabel"
                class="flex-grow-1"
                :additional-query="{type: 'customer'}"
            />
          </div>
          <div class="col-12">
            <div class="d-flex align-items-center justify-content-between filter-label">
              <label for="colFormLabel" class="form-label">Buyer Name: </label>
              <span class="filter-search-icon cursor-pointer" @click="onOpenContactSearchModal">
                  <i class="fas fa-search"></i>
              </span>
            </div>

            <AsyncSelect
                placeholder="Select Buyer"
                v-model="buyerProfile"
                :api-service="fetchContactProfiles"
                :format-label="formatPatientLabel"
                class="flex-grow-1"
                :additional-query="{type: 'buyer'}"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="p-2">
      <div class="px-2 row">
        <div class="col-md-8">
          <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item">
              <a @click="activeComponentSet('material')" class="active nav-link" data-bs-toggle="tab" aria-controls="home"
                 role="tab" aria-selected="true">RM & PM Consumption</a>
            </li>
            <li class="nav-item">
              <a @click="activeComponentSet('direct')" class="nav-link" data-bs-toggle="tab" aria-controls="profile"
                 role="tab" aria-selected="false">Labour & Direct Overhead</a>
            </li>
          </ul>
        </div>
        <div class="col-md-4">
          <v-select
              placeholder="Select RM warehouse Location"
              v-model="formData.raw_material_location_id"
              :options="locations"
              label="text"
              :reduce="text => text.id"
              @option:selected="resetForm"
          />
        </div>
      </div>
      <component :is="activeComponent" :items="activeItems"/>
    </div>
    <div class="pb-5 px-2">
      <div class="d-flex flex-wrap gap-1 gy-2 justify-content-center">
        <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="handleSubmit(true)" class="btn btn-primary">
          Save
        </button>
        <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="handleSubmit(false)"
                class="btn btn-primary">Save & New
        </button>
        <button :disabled="productLoader" @click="navigateToListPage" class="btn btn-outline-secondary">Cancel</button>
      </div>
    </div>

    <GlobalLoader/>
    <ContactDropdownFilter
        v-if="store.state.isModalOpenThree"
        @onSearchContact="onSearchContact"
        type="supplier"
        :companyRoles="companyRoles"
    />
  </div>
</template>

<script setup>
import {computed, inject, onMounted, ref, watch} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import {useStore} from "vuex";
import useDate from "@/services/utils/day";
import handleContact from '@/services/modules/contact'
import TitleButton from '@/components/atom/TitleButton'
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import ContactDropdownFilter from "@/components/atom/Contact/ContactDropdownFilter.vue";
import MaterialComponentTable from '@/components/molecule/company/inventory/production/MaterialComponentTable.vue'
import DirectComponentTable from '@/components/molecule/company/inventory/production/DirectComponentTable.vue'
import GlobalLoader from "@/components/atom/GlobalLoader.vue";
import {generateTxnNumber} from "@/services/utils/voucherNumberGenerator";
import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";
import handlePurchase from '@/services/modules/purchase'
import handleRole from "@/services/modules/role";
import handleBusinessesLocations from '@/services/modules/businessesLocations'

const date = useDate();
const router = useRouter()
const route = useRoute()
const store = useStore()

const showError = inject('showError');
const showSuccess = inject('showSuccess');

const {fetchProductCosting, fetchSingleProductCosting, storeProduction} = handlePurchase()
const {fetchBusinessLocationsList} = handleBusinessesLocations()
const {fetchContactProfiles} = handleContact()
const {formatPatientLabel} = useAsyncDropdownHelper();
const {fetchCompanyDefaultRoles} = handleRole();

const companyId = computed(() => {
  return route.params.companyId
})
let loader = ref(false)
let productLoader = ref(false)
let saveButtonLoader = ref(false)
let saveNewButtonLoader = ref(false)
let products = ref([])
let accountHeads = ref([])
let contacts = ref([])
let business = ref([])
let locations = ref([])
let accountPayable = ref([])
let prefix = ref('')
let serial = ref('')
const discountAmount = ref(0);
const productCosting = ref(null);
const contactProfile = ref(null);
const buyerProfile = ref(null);
const companyRoles = ref([]);
const materialItems = ref([]);
const directItems = ref([]);
const activeComponent = ref()
const activeItems = ref([])

let formData = ref({
  company_id: companyId,
  product_costing_master_id: null,
  product_id: null,
  contact_profile_id: null,
  buyer_id: null,
  productCosting: null,
  product_name: null,
  status: 'active',
  batch_no: null,
  expire_date: null,
  production_quantity: 0,
  rate: null,
  finished_goods_value: null,
  date: '',
  finished_goods_location_id: null,
  raw_material_location_id: null,
  description: null
})

watch(productCosting, (newValue) => {
  if (newValue) {
    getProductCostingDetails(newValue.id)
  }
});

const components = {
  direct: DirectComponentTable,
  material: MaterialComponentTable
}

const activeComponentSet = (type) => {
  if (type === 'direct') {
    activeComponent.value = components[type];
    return activeItems.value = directItems.value
  }

  activeComponent.value = components[type];
  return activeItems.value = materialItems.value
}

const getRateAmount = computed(() => {
  const totalMaterialAmount = materialItems.value.reduce((total, amount) => total + parseFloat(amount.amount), 0).toFixed(4);
  const totalDirectAmount = directItems.value.reduce((total, amount) => total + parseFloat(amount.amount), 0).toFixed(4);

  return formData.value.rate = (parseFloat(totalMaterialAmount) + parseFloat(totalDirectAmount)).toFixed(4);
})

const getFGValueAmount = computed(() => {
  return formData.value.finished_goods_value = (parseFloat(getRateAmount.value) * formData.value.production_quantity).toFixed(4);
})

const getProductCostingDetails = (id) => {
  fetchSingleProductCosting(id, `?company_id=${route.params.companyId}`).then(({data, status, message}) => {
    if (!status) {
      return showError(message)
    }

    formData.value.product_costing_master_id = data.id
    formData.value.product_id = data.product_id

    materialItems.value = [];
    directItems.value = [];
    formData.value.product_name = data.product.name
    data.general.forEach((item) => {
      const {id:product_costing_general_id,product_costingable, product_costingable_type, product_costingable_id, type, total_quantity, rate, product_price_by_method, amount} = item
      const {name} = product_costingable
      if (item.type === 'raw_materials' || item.type === 'packing_materials') {
        materialItems.value.push({name,product_costing_general_id, product_costingable_type, product_costingable_id, type, total_quantity, rate:product_price_by_method, amount});
      } else if (item.type === 'direct_overhead' || item.type === 'direct_labor') {
        directItems.value.push({name, product_costing_general_id, product_costingable_type, product_costingable_id, type, total_quantity, rate:rate, amount});
      }
    });

    activeComponentSet('material')
  })
}

onMounted(async () => {
  loader.value = true
  let voucher = await generateTxnNumber(`?company_id=${route.params.companyId}&voucher_type=journal_voucher&txn_type=manufacturing_journal`);
  prefix.value = voucher.prefix;
  serial.value = voucher.serial;
  formData.value.date = date.currentDate();
  const companyQuery = `?company_id=${companyId.value}`
  const businessLocationRes = fetchBusinessLocationsList(companyQuery)
  Promise.all([
    fetchCompanyDefaultRoles(companyQuery).then(res => {
      if (res.data) companyRoles.value = res.data
    }),
    businessLocationRes.then(res => {
      if (res.data) locations.value = res.data
    }),
  ]).then(() => loader.value = false).catch(() => loader.value = false)
})

watch(contactProfile, (newVal) => {
  if (!newVal) {
    return formData.value.contact_profile_id = null;
  }

  formData.value.contact_profile_id = newVal.id;
})

watch(buyerProfile, (newVal) => {
  if (!newVal) {
    return formData.value.buyer_id = null;
  }

  formData.value.buyer_id = newVal.id;
})

function navigateToListPage() {
  router.push({name: 'production-list', params: route.params, query: route.query})
}

function onOpenContactSearchModal() {
  store.state.isModalOpenThree = true;
}

function onSearchContact(contact) {
  contactProfile.value = contact
}

function handleSubmit(redirect = false) {
  if (redirect) {
    saveButtonLoader.value = true
  } else {
    saveNewButtonLoader.value = true
  }

  formData.value.bill_number = prefix.value + '-' + serial.value
  formData.value.material_items = materialItems.value
  formData.value.direct_items = directItems.value
  if (!formData.value.date) formData.value.date = date.currentDate()

  storeProduction(formData.value).then(res => {
    saveButtonLoader.value = false
    saveNewButtonLoader.value = false
    if (!res.status) {
      return showError(res.message)
    }

    showSuccess(res.message)
    if (redirect) navigateToListPage()
    resetForm()
  }).catch(() => {
    saveButtonLoader.value = false
    saveNewButtonLoader.value = false
    productLoader.value = false
  })
}

// have to refactor
function resetForm() {

}

</script>
