<template>
  <tr>
    <td>{{ item.name }}</td>
    <td>{{ item.total_quantity }}</td>
    <td><input class="form-control" type="text" v-model="item.rate"></td>
    <td>{{ totalAmount }}</td>
  </tr>
</template>

<script setup>

import {computed} from "vue";

const props = defineProps(['item'])

const totalAmount = computed(() => {
  const sumAmount = (props.item.total_quantity * props.item.rate);
  return props.item.amount = sumAmount.toFixed(4)
});

</script>
